// TODO: Update domain list on new patch (this is the same as server)
// Source: https://game8.co/games/Genshin-Impact/archives/297458

const ARTIFACT_DOMAINS = [
    'Midsummer Courtyard',
    'Valley of Remembrance',
    'Domain of Guyun',
    'Hidden Palace of Zhou Formula',
    'Clear Pool and Mountain Cavern',
    'Peak of Vindagnyr',
    'Ridge Watch',
    'Momiji-Dyed Court',
    'Slumbering Court',
    'The Lost Valley',
    'Spire of Solitary Enlightenment',
    'City of Gold',
    'Molten Iron Fortress',
    'Denouement of Sin'
  ];
  
  const WEAPON_ASCENSION_DOMAINS = [
    'Cecilia Garden',
    'Hidden Palace of Lianshan Formula',
    'Court of Flowing Sand',
    'Tower of Abject Pride',
    'Echoes of the Deep Tides'
  ];
  
  const TALENT_ASCENSION_DOMAINS = [
    'Forsaken Rift',
    'Taishan Mansion',
    'Violet Court',
    'Steeple of Ignorance',
    'Pale Forgotten Glory'
  ];
  
  export default [
    ...ARTIFACT_DOMAINS,
    ...WEAPON_ASCENSION_DOMAINS,
    ...TALENT_ASCENSION_DOMAINS
  ];
  