import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingScreen from '../components/LoadingScreen';
import authService from '../services/authService';
import { accountActions } from '../slices/accountSlice';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(accountActions.logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        await authService
          .loginWithToken()
          .then(
            async response =>
              await dispatch(accountActions.loginSuccess(response))
          )
          .catch(async err => {
            if (err.status === 401)
              await dispatch(accountActions.loginError(err));
          });
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
