import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { includes } from 'lodash';

function RoleGuard(props) {
  const account = useSelector(state => state.account);

  if (props.roles) {
    if (!includes(props.roles, account.currentUser.role))
      return <Redirect to="/"></Redirect>;
  }

  return props.children;
}

RoleGuard.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.array
};

export default RoleGuard;
