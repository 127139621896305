import { chain, get } from 'lodash';

const CoerceError = function(error) {
  const msg = chain(error)
    .get('response.data.errors', [
      get(
        error,
        'response.data.message',
        get(error, 'response.statusText', get(error, 'message'))
      )
    ])
    .flatMap(v => v)
    .join(', ')
    .value();

  this.message = msg;
  this.status = get(error, 'response.status', get(error, 'status', ''));
  this.errors = get(error, 'response.data.errors', get(error, 'errors', ''));
};

CoerceError.prototype = Object.create(Error.prototype);
CoerceError.prototype.constructor = CoerceError;

export default function coerce(error) {
  return new CoerceError(error);
}
