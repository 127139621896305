import 'react-perfect-scrollbar/dist/css/styles.css';
import './mixins/chartjs';
import './mixins/prismjs';
import { enableES5 } from 'immer';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from './App';
import { configureStore } from './store';

enableES5();

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
