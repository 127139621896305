import Api from '..';

const CharacterAssetAPI = {
  async getAllCharacterAssets() {
    return Api.get('/game-assets/character/all');
  },
  async createCharacterAsset(data) {
    return Api.post(`/game-assets/character/create`, { data });
  },
  async updateCharacterAsset(characterName, data) {
    return Api.put(`/game-assets/character/${characterName}`, { data });
  },
  async deleteCharacterAsset(characterName, data) {
    return Api.delete(`/game-assets/character/${characterName}`);
  }
};

export default CharacterAssetAPI;
