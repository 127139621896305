export default {
  h1: {
    fontWeight: 700,
    fontSize: 35,
    letterSpacing: '2px',
    fontFamily: 'Poppins'
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px',
    fontFamily: "Inter"
  },
  h3: {
    fontWeight: 350,
    fontSize: 24,
    letterSpacing: '-0.06px',
    fontFamily: "Inter"
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px',
    fontFamily: "Inter"
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
    fontFamily: "Inter"
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
    fontFamily: "Inter"
  },
  overline: {
    fontWeight: 500,
    fontFamily: "Inter"
  },
  caption: {
    fontFamily: 'Comforter',
    fontWeight: 500,
    fontSize: 45,
    letterSpacing: '-0.05px',
    fontStyle: 'italic'
  }
};
