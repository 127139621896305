import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';
import { TOKEN_STORAGE_KEY } from '../constants';
import Auth from '../api/Auth';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  logout = () => {
    this.setSession(null);
  };

  loginWithToken = () =>
    new Promise((resolve, reject) => {
      Auth.getCurrentUser()
        .then(resolve)
        .catch(reject);
    });

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem(TOKEN_STORAGE_KEY);

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
