import { createSlice } from '@reduxjs/toolkit';
import CharacterAssetAPI from '../api/game-assets/character-asset';

const initialState = {
  characterAssets: []
};

const gameAssetSlice = createSlice({
  name: 'gameAssets',
  initialState,
  reducers: {
    setCharacterAssets: (state, action) => {
      const characterAssets = action.payload;
      const characterAssetMapping = characterAssets.reduce(
        (mapping, asset) => ({ ...mapping, [asset.name]: asset }),
        {}
      );
      return {
        ...state,
        characterAssets: characterAssetMapping
      };
    }
  }
});

export const {
  reducer: gameAssetReducer,
  actions: gameAssetActions
} = gameAssetSlice;

export default gameAssetReducer;
