/**
 * This file specifies the state of the application in response to App actions
 */
import { createSlice } from '@reduxjs/toolkit';
import { TOKEN_STORAGE_KEY } from '../constants';
import authService from '../services/authService';

const initialState = {
  authToken: authService.getAccessToken(),
  isLoggedIn: !!authService.getAccessToken(),
  currentUser: null,
  gettingCurrentUser: false,
  getCurrentUserError: null,
  signingUp: false,
  signupError: null,
  loggingIn: false,
  loginError: false
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    signup: state => ({ ...state, signingUp: true }),
    signupError: (state, action) => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);

      return {
        ...state,
        currentUser: null,
        signupError: action.payload.error,
        signingUp: false
      };
    },
    signupSuccess: state => ({
      ...state,
      signupError: null,
      signingUp: false
    }),
    login: state => ({ ...state, loggingIn: true }),
    loginError: (state, action) => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);

      return {
        ...state,
        authToken: null,
        currentUser: null,
        isLoggedIn: false,
        loginError: action.payload.error,
        loggingIn: false
      };
    },
    loginSuccess: (state, action) => {
      localStorage.setItem(TOKEN_STORAGE_KEY, action.payload.authToken);

      return {
        ...state,
        currentUser: action.payload.user,
        isLoggedIn: true,
        loginError: null,
        loggingIn: false
      };
    },
    logout: state => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);

      return {
        ...state,
        authToken: null,
        isLoggedIn: false,
        currentUser: null,
        gettingCurrentUser: false,
        getCurrentUserError: null,
        signingUp: false,
        signupError: null,
        loggingIn: false,
        loginError: false
      };
    },
    getCurrentUser: state => ({ ...state, gettingCurrentUser: true }),
    getCurrentUserError: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      getCurrentUserError: action.payload.error
    }),
    getCurrentUserSuccess: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      currentUser: action.payload.user
    })
  }
});

export const { reducer: accountReducer, actions: accountActions } = slice;

export default accountReducer;
