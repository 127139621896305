/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { colors, responsiveFontSizes, createMuiTheme } from '@material-ui/core';
import { softShadows, strongShadows } from './shadows';
import { THEMES } from '../constants';
import typography from './typography';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const failBackThemeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      selected: {
        active: 'rgba(84, 110, 122,.454)'
      },
      background: {
        default: colors.common.black,
        dark: '#11151c',
        paper: colors.common.white
      },
      primary: {
        main: '#dab978'
      },
      secondary: {
        main: '#d6ba81'
      },
      tertiary: {
        main: '#252627'
      },
      text: {
        primary: '#dab978',
        secondary: '#e1e6f4'
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      selected: {
        active: 'rgba(84, 110, 122,.454)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#0080C8'
      },
      tertiary: {
        main: '#89CAC5'
      },
      text: {
        primary: '#8a85ff',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      selected: {
        active: 'rgba(84, 110, 122,.454)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      tertiary: {
        main: '#89CAC5'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export function createTheme(settings = {}, themeConfigsIn) {
  if (!themeConfigsIn) {
    themeConfigsIn = failBackThemeConfigs;
  }

  let themeConfig = themeConfigsIn.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    [themeConfig] = themeConfigsIn;
  }

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
