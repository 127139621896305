export default {
  normal_attack: 'Normal Attack',
  charged_attack: 'Charged Attack',
  plunge_attack: 'Plunge Attack',
  elemental_skill: 'Elemental Skill',
  elemental_burst: 'Elemental Burst',
  a1_talent: 'A1 Talent',
  a4_talent: 'A4 Talent',
  other: 'Other'
};
