import Api from '../api';

const AuthAPI = {
  getCurrentUser() {
    return Api.get(`/authentication/current`);
  },
  login(data) {
    return Api.post(`/authentication`, { data });
  },
  signup(data) {
    return Api.post(`/users`, { data });
  }
};

export default AuthAPI;
