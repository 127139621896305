/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import RoleGuard from './components/RoleGuard';
import LoadingScreen from './components/LoadingScreen';
import { CURRENT_ABYSS_VERSION } from './constants';
import { assign } from 'lodash';

const speedrunRoutes = [
  {
    exact: true,
    path: '/speedrun/abyss/leaderboard',
    component: lazy(() => import('./views/public/SpeedrunLeaderboards/Abyss')),
    title: 'The Golden House | Abyss Speedrun Leaderboard',
    description: 'The Golden House Spiral Abyss Speedrunning Leaderboard',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/speedrun/domain/leaderboard',
    component: lazy(() => import('./views/public/SpeedrunLeaderboards/Domain')),
    title: 'The Golden House | Domain Speedrun Leaderboard',
    description: 'The Golden House Domain Speedrun Leaderboard',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/speedrun/event/leaderboard',
    component: lazy(() => import('./views/public/SpeedrunLeaderboards/Event')),
    title: 'The Golden House | Event Speedrun Leaderboard',
    description: 'The Golden House Event Speedrun Leaderboard',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/speedrun/weekly-boss/leaderboard',
    component: lazy(() =>
      import('./views/public/SpeedrunLeaderboards/WeeklyBoss')
    ),
    title: 'The Golden House | Weekly Boss Speedrun Leaderboard',
    description: 'The Golden House Weekly Boss Speedrun Leaderboard',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/speedrun/world-boss/leaderboard',
    component: lazy(() =>
      import('./views/public/SpeedrunLeaderboards/WorldBoss')
    ),
    title: 'The Golden House | World Boss Speedrun Leaderboard',
    description: 'The Golden House World Boss Speedrun Leaderboard',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/leaderboards/abyss',
    component: lazy(() => import('./views/public/Standings/Abyss')),
    title: `The Golden House | Speedrunning Abyss Leaderboard ${CURRENT_ABYSS_VERSION}`,
    description: 'The Golden House Spiral Abyss Player Rankings Leaderboard',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  }
];
const standardDPSRoutes = [
  {
    exact: true,
    path: '/dps/abyss',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Standard/Abyss')
    ),
    title: 'The Golden House | Abyss DPS Leaderboard'
  },
  {
    exact: true,
    path: '/dps/domain',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Standard/Domain')
    ),
    title: 'The Golden House | Domain DPS Leaderboard'
  },
  {
    exact: true,
    path: '/dps/event',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Standard/Event')
    ),
    title: 'The Golden House | Event DPS Leaderboard'
  },
  {
    exact: true,
    path: '/dps/overworld',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Standard/Overworld')
    ),
    title: 'The Golden House | DPS Submissions'
  },
  {
    exact: true,
    path: '/dps/world-boss',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Standard/WorldBoss')
    ),
    title: 'The Golden House | World Boss DPS Submissions'
  },
  {
    exact: true,
    path: '/dps/weekly-boss',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Standard/WeeklyBoss')
    ),
    title: 'The Golden House | DPS Submissions'
  },
  {
    exact: true,
    path: '/dps/reputation-bounty',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Standard/ReputationBounty')
    ),
    title: 'The Golden House | Reputation Bounty DPS Submissions'
  }
];
const restrictedDPSRoutes = [
  {
    exact: true,
    path: '/restricted-dps/abyss',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Restricted/Abyss')
    ),
    title: 'The Golden House | Abyss Restricted DPS Leaderboard'
  },
  {
    exact: true,
    path: '/restricted-dps/domain',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Restricted/Domain')
    ),
    title: 'The Golden House | Domain Restricted DPS Leaderboard'
  },
  {
    exact: true,
    path: '/restricted-dps/event',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Restricted/Event')
    ),
    title: 'The Golden House | Event Restricted DPS Leaderboard'
  },
  {
    exact: true,
    path: '/restricted-dps/overworld',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Restricted/Overworld')
    ),
    title: 'The Golden House | Overworld Restricted DPS Submissions'
  },
  {
    exact: true,
    path: '/restricted-dps/world-boss',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Restricted/WorldBoss')
    ),
    title: 'The Golden House | World Boss Restricted DPS Submissions'
  },
  {
    exact: true,
    path: '/restricted-dps/weekly-boss',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Restricted/WeeklyBoss')
    ),
    title: 'The Golden House | Weekly Boss Restricted DPS Submissions'
  },
  {
    exact: true,
    path: '/restricted-dps/reputation-bounty',
    component: lazy(() =>
      import('./views/public/DPSLeaderboards/Restricted/ReputationBounty')
    ),
    title: 'The Golden House | Reputation Bounty Restricted DPS Submissions'
  }
];

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: lazy(() => import('./views/public/HomePageView')),
    title: 'The Golden House | Home',
    description:
      "The Golden House is Genshin's #1 space for Speedrunning, Damage per Screenshot & Hyper-Investment",
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView')),
    title: 'The Golden House | Log In',
    description: 'The Golden House Login',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/register',
    component: lazy(() => import('./views/auth/RegisterView')),
    title: 'The Golden House | Register',
    description: 'The Golden House Register',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  ...standardDPSRoutes,
  ...restrictedDPSRoutes,
  ...speedrunRoutes,
  {
    exact: true,
    path: '/user-verification',
    component: lazy(() => import('./views/public/User/UserVerificationView')),
    title: 'The Golden House | Account Verification',
    description: 'The Golden House Account Verification',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'noindex,nofollow'
  },
  {
    exact: true,
    path: '/reset-password',
    component: lazy(() => import('./views/public/User/UserResetPasswordView')),
    title: 'The Golden House | Reset Password',
    description: 'The Golden House Password Reset',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'noindex,nofollow'
  },
  {
    exact: true,
    path: '/update-password',
    component: lazy(() => import('./views/public/User/UserUpdatePasswordView')),
    title: 'The Golden House | Update Password',
    description: 'The Golden House Update Password',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'noindex,nofollow'
  },
  {
    exact: false,
    path: '/public-profile',
    component: lazy(() => import('./views/public/PublicUserProfileView')),
    title: 'The Golden House | Player Profile',
    description:
      'The Golden House player profile. View statistics such as most used characters, character builds, and run attempts.',
    keywords: 'Genshin Impact, Speedrunning',
    robots: 'index,follow'
  },
  {
    exact: true,
    path: '/privacy',
    component: lazy(() => import('./views/public/PrivacyPolicyView')),
    title: 'The Golden House | Privacy Policy',
    description: 'The Golden House privacy policy',
    keyword: 'Genshin Impact, Speedrunning',
    robots: 'noindex,nofollow'
  },
  {
    path: '/private',
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/private/speedrun/approval',
        component: lazy(() => import('./views/agent/SpeedrunApprovalView')),
        title: 'The Golden House | Speedrun Agent Approval',
        roles: ['AGENT', 'ADMIN']
      },
      {
        exact: true,
        path: '/private/dps/approval',
        component: lazy(() => import('./views/agent/DpsApprovalView')),
        title: 'The Golden House | DPS Agent Approval',
        roles: ['AGENT', 'ADMIN']
      },
      {
        exact: true,
        path: '/private/restricted-dps/approval',
        component: lazy(() => import('./views/agent/F2PDpsApprovalView')),
        title: 'The Golden House | F2P DPS Agent Approval',
        roles: ['AGENT', 'ADMIN']
      },
      {
        exact: true,
        path: '/private/agent/dashboard',
        component: lazy(() => import('./views/agent/AgentDashboardView')),
        title: 'The Golden House | Agent Dashboard',
        roles: ['AGENT', 'ADMIN']
      },
      {
        exact: true,
        path: '/private/submit-run',
        component: lazy(() =>
          import('./views/public/SubmissionForms/Speedrun')
        ),
        title: 'The Golden House | Speedrun Submission Form',
        roles: ['MEMBER', 'AGENT', 'ADMIN']
      },
      {
        exact: true,
        path: '/private/submit-dps',
        component: lazy(() => import('./views/public/SubmissionForms/DPS')),
        title: 'The Golden House | DPS Submission Form',
        roles: ['MEMBER', 'AGENT', 'ADMIN']
      },
      {
        exact: true,
        path: '/private/submit-restricted-dps',
        component: lazy(() => import('./views/public/SubmissionForms/F2P-DPS')),
        title: 'The Golden House | Restricted DPS Submission Form',
        roles: ['MEMBER', 'AGENT', 'ADMIN']
      },
      {
        exact: true,
        path: '/private/edit-ceiling',
        component: lazy(() => import('./views/agent/DpsCeilingSubmissionView')),
        title: 'The Golden House | Update DPS Ceiling',
        roles: ['AGENT', 'ADMIN']
      },
      {
        path: '/private/user-profile',
        component: lazy(() => import('./views/public/User/UserProfileView')),
        title: 'The Golden House | User Profile',
        roles: ['MEMBER', 'AGENT', 'ADMIN']
      },
      {
        path: '/private/admin/dashboard',
        component: lazy(() => import('./views/admin/AdminDashboardView')),
        title: 'The Golden House | Admin Dashboard',
        roles: ['ADMIN']
      },
      {
        path: '/private/admin/user-management',
        component: lazy(() =>
          import('./views/admin/AdminDashboardView/UserManagementView')
        ),
        title: 'The Golden House | User Management',
        roles: ['ADMIN']
      },
      {
        exact: true,
        path: '/private/admin/character-assets',
        component: lazy(() => import('./views/admin/CharacterAssetDashboard')),
        title: 'The Golden House | Update Character Assets',
        roles: ['ADMIN']
      }
    ]
  },
  {
    component: () => <div>The page you are trying to reach doesn't exist.</div>
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          const roleProps = assign(
            {},
            route.roles ? { roles: route.roles } : {}
          );

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <RoleGuard {...roleProps}>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component
                          {...props}
                          helmet={
                            <Helmet>
                              <title>{route.title}</title>
                              <meta
                                name="description"
                                content={route.description}
                              />
                              <meta name="keywords" content={route.keywords} />
                              <meta name="robots" content={route.robots} />
                            </Helmet>
                          }
                        />
                      )}
                    </Layout>
                  </RoleGuard>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
