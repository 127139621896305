/**
 * App saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { accountActions } from '../slices/accountSlice';
import AuthAPI from '../api/Auth';
import handleError from '../utils/saga/handleError';

/**
 * Fetches the current user and dispatches success or error.
 */
export function* getCurrentUser() {
  try {
    const response = yield call(AuthAPI.getCurrentUser);
    yield put(accountActions.getCurrentUserSuccess(response));
  } catch (err) {
    yield handleError(accountActions.getCurrentUserError, err);
  }
}

export function* signup({ payload: { userData, resolve, reject } }) {
  try {
    const response = yield call(AuthAPI.signup, userData);
    yield put(accountActions.signupSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(accountActions.signupError, err, reject);
  }
}

export function* login({ payload: { credentials, resolve, reject } }) {
  try {
    const response = yield call(AuthAPI.login, credentials);
    yield put(accountActions.loginSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(accountActions.loginError, err, reject);
  }
}

/**
 * Gets the response object from the latest request fired
 * to get the current user.
 */
function* accountSaga() {
  yield takeLatest(accountActions.getCurrentUser.type, getCurrentUser);
  yield takeLatest(accountActions.signup.type, signup);
  yield takeLatest(accountActions.login.type, login);
}

export default accountSaga;
